import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Wrapper from '../components/wrapper'
import Header from '../components/header'
import { margin, footerDistance } from '../distances'

export default ({
  data: {
    hero,
    page: { frontmatter },
  },
}) => (
  <Layout>
    <Header
      background={hero.image.fixed}
      showButton={false}
      title={frontmatter.heroTitle}
      seoTitle={frontmatter.seoTitle}
      seoDescription={frontmatter.seoDescription}
    />
    <Wrapper styles={margin('bottom', footerDistance)}>
      <div dangerouslySetInnerHTML={{ __html: frontmatter.content }} />
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query Page($url: String!) {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      image: childImageSharp {
        fixed(width: 1600, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    page: markdownRemark(frontmatter: { url: { eq: $url } }) {
      frontmatter {
        heroTitle
        seoTitle
        seoDescription
        content
      }
    }
  }
`
